import { FC } from "react";
import { ImageCard } from "../../../../components/ImageCard";

export const AppResourcesSection: FC = () => {
    return (
        <section className="bg-secondary">
            <div className="grid grid-cols-12 gap-6 py-16 xl:px-8">
                <div className="col-span-12 md:col-span-6">
                    <div className="col-span-6">
                        <img src={require('../../../../resources/images/dog-call.png')} loading="lazy" alt="guy chat with a dog" width={600} />
                        <h2 className="max-w-4xl text-center my-6 text-3xl font-extrabold tracking-tight leading-none md:text-4xl xl:text-5xl text-slate-800">Faça mais com um <span className="text-sky-400"> aplicativo</span> completo <span className="text-sky-400"> cheio</span> de recursos</h2>
                    </div>
                </div>

                <div className="col-span-12 md:col-span-6">
                    <div className="px-4 md:px-12 py-8 flex flex-col">
                        <div className="grid grid-cols-6 items-center justify-center mt-12 gap-6">
                            <ImageCard className="bg-sky-200 col-span-6 md:col-span-3"
                                icon={require('../../../../resources/images/cofrinho.png')}
                                title={`Versões gratuitas para sempre`}
                                content={`Aproveite a versão gratuita para sempre. Sem pegadinhas, sem limites!`} />
                            <ImageCard className="bg-red-200 col-span-6 md:col-span-3"
                                icon={require('../../../../resources/images/terra.png')}
                                title={`Acesse de qualquer lugar do planeta`}
                                content={`Programe iterações ou faça video chamadas de qualquer lugar!`} />

                            <ImageCard className="bg-green-300/60 col-span-6 md:col-span-3"
                                icon={require('../../../../resources/images/estetoscopio.png')}
                                title={`Atendimento on-line para seu pet`}
                                content={`Solicite atendimentos clínicos por video chamada para seu pet.`} />

                            <ImageCard className="bg-purple-300/60 col-span-6 md:col-span-3"
                                icon={require('../../../../resources/images/lembrete.png')}
                                title={`Adicione seus lembretes`}
                                content={`Nunca mais perca um retorno com nossa gestão inteligente de vacinas.`} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};