import React, { FC } from 'react';
import { ToolbarBrand } from './components/Brand';
import { LinkItem } from './components/LinkItem';
import { ToolbarToogle } from './components/Toogle';
import { useToolbar } from './index.hook';

export const Toolbar: FC = () => {
    const { handleToogleButton, toogled } = useToolbar();
    const _className = `${toogled ? '' : 'hidden'}  justify-between items-center w-full lg:flex lg:w-auto lg:order-1 rounded-md px-4 py-2 mt-2`;
    return (
        <header>
            <nav className="bg-sky-400 px-4 py-4 fixed w-full">
                <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
                    <ToolbarBrand />

                    <div className="flex">
                        <ToolbarToogle onToogle={handleToogleButton} />
                    </div>

                    <div className={_className}>
                        <ul className="flex flex-col font-medium text-sm lg:flex-row lg:space-x-8 mt-4 lg:mt-0">
                            <LinkItem label='Home' href='/' active={true} />
                            <LinkItem label='Recursos' href='#recursos' />
                            <LinkItem label='Contato' href='#contato' />
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
};