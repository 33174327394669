import { FC } from "react";

export type ToolbarLinkItemProps = {
    active?: boolean;
    label?: string;
    rel?: string;
    href?: string;
    target?: string;
    onclick?: () => void;
}

export const LinkItem: FC<ToolbarLinkItemProps> = (props) => {
    return (
        <li>
            <a href={props.href} onClick={() => props.onclick} className={props.active ? "bg-yellow-200 text-sky-500 p-2.5 rounded-md" : "text-white hover:underline hover:decoration-yellow-00 hover:decoration-2 hover:underline-offset-8"}
                target={props.target ?? "_self"} rel={props.rel}>
                {props.label}
            </a>
        </li>
    );
};