import React, { FC } from "react";
import { Toolbar } from "../../../../components/Toolbar";

export type HeaderSectionProps = {
    onCtaClick?: () => void
}

export const HeaderSection: FC<HeaderSectionProps> = (props) => {

    return (
        <section>
            <Toolbar />
            <div className="grid grid-cols-12 gap-6 py-8 pt-24">
                <div className="col-span-12 md:col-span-6">
                    <div className="px-4 md:px-12 py-8 pt-24 lg:pt-36 flex flex-col justify-center items-center ">
                        <h1 className="max-w-4xl text-center mb-4 font-extrabold tracking-tight leading-none text-3xl md:text-4xl xl:text-5xl text-white">Demonstre todo seu <span className="text-yellow-200">carinho</span>
                            {' '}por seu <span className="text-yellow-200">pet 🐶</span> mesmo de longe</h1>
                    </div>

                    <div className="flex justify-center items-center pt-4">
                        <button type="button" onClick={() => props.onCtaClick?.call(this)}
                            className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-slate-700 rounded-lg bg-yellow-200 hover:bg-yellow-300 focus:bg-yellow-400 focus:ring-0">
                            Quero conhecer
                        </button>
                    </div>
                </div>

                <div className="col-span-12 md:col-span-6">
                    <div className="col-span-6">
                        <img src={require('../../../../resources/images/facetime-call.png')} height={60} loading="lazy" alt="mockup" width={900} />
                    </div>
                </div>
            </div>
        </section>
    );
};