import axios from "axios";
import { ContactFormData } from "../components/ContactForm/types/index.";

export class MailService {
    static sendMail = async (data: ContactFormData) => {
        const token = 'eyJpdiI6ImU2U21Ob2ZDMGVUelZEVXZTaTl3Y0E9PSIsInZhbHVlIjoiYzYzcnN3NVBLSmdyaWVQdm41UGRoRVhrOVZKVUQ4TDcrTDlEaTZ2S0ptMD0iLCJtYWMiOiJhNTEzYWJkN2JhMmRjMTUyMDU5MWE0OTkyOGQwMzRhY2QxOTViNWU2MmQxZTBiMGMxYWU3ZWJhZjQwZDJiNGQ5In0=';

        let url = `https://lic.codeinformatica.com.br/api/sendmail`;
        url += `?nome=${data.nome?.trim()}`;
        url += `&email=${data.email?.trim()}`;
        url += `&empresa=${data.empresa?.trim()}`;
        url += `&telefone=${data.telefone?.trim()}`;
        url += `&setor=${data.setor?.trim()}`;
        url += `&cargo=${data.cargo?.trim()}`;
        url += `&segmento=${data.segmento?.trim()}`;
        url += `&faturamento=${data.faturamentoAnual?.toString()}`;
        url += `&origem=${data.origem?.trim()}`;
        url += `&mensagem=${data.mensagem?.trim()}`;
        url += `&lgpd=${data.lgpd ? 'Termos aceitos' : 'Termos não aceitos'}`;
        url += `&token=${token}`;

        return await axios.get(url);
    }
} 