import React, { FC } from "react";
import { HiMenuAlt3 } from 'react-icons/hi'

export type ToolbarToogleProps = {
    onToogle?: () => void;
}

export const ToolbarToogle: FC<ToolbarToogleProps> = (props) => {
    return (
        <div className="flex items-center lg:order-3">
            <button type="button" onClick={() => props.onToogle} className="inline-flex items-center p-2 ml-1 text-sm text-secondary rounded-lg lg:hidden hover:text-secondary focus:outline-none focus:text-secondary">
                <span className="sr-only">Open main menu</span>
                <HiMenuAlt3 className="w-5 h-5" />
            </button>
        </div>
    )
}