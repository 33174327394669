import { FC, FormEvent, useRef } from "react";
import { TextInput } from 'flowbite-react'

export type WaitlistSectionProps = {
    onCtaClick?: (email: string) => void;
}

export const WaitlistSection: FC<WaitlistSectionProps> = (props) => {
    const refEmailInput = useRef<HTMLInputElement>(null);

    const handleSubmit = (e: FormEvent<HTMLFormElement> | undefined) => {
        e?.preventDefault();
        props.onCtaClick?.call(this, refEmailInput.current?.value ?? "");
        if (refEmailInput.current !== null) refEmailInput.current!.value = "";
    }

    return (
        <section className="py-24 xl:px-8 flex items-center justify-center flex-col">
            <h1 className="max-w-4xl text-center mb-4 font-extrabold tracking-tight leading-none text-3xl md:text-4xl xl:text-5xl text-white">Quer saber mais?</h1>
            <h2 className="max-w-4xl text-center mb-4 tracking-tight leading-none text-lg md:text-xl xl:text-1xl text-yellow-200">Digite seu e-mail para entrar na lista de espera</h2>


            <div className="mt-8 w-full px-6 md:px-48">
                <form className="flex flex-col md:flex-row items-center justify-center" action="#" onSubmit={handleSubmit}>
                    <div className="w-full mb-4 md:mb-0">
                        <TextInput placeholder="Digite seu melhor e-mail" type={"email"} ref={refEmailInput} required />
                    </div>
                    <button type="submit" className="inline-flex items-center justify-center ml-1 px-5 py-2 text-base font-medium text-center text-slate-700 rounded-lg bg-yellow-200 hover:bg-yellow-300 focus:bg-yellow-400 focus:ring-0">
                        Solicitar
                    </button>
                </form>
            </div>
        </section>
    );
};