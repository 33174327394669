import React, { FC, useState } from "react";
import ContactForm from "../../components/ContactForm";
import { ContactFormData, defaultFormData } from "../../components/ContactForm/types/index.";
import { Footer } from "../../components/Footer";
import { AppResourcesSection } from "./components/AppResources";
import { HeaderSection } from "./components/Header";
import { WaitlistSection } from "./components/Waitlist";

export const HomePage: FC = () => {
    const [showContactForm, setShowContactForm] = useState<boolean>(false);
    const [sendingContactForm, setSendingContactForm] = useState<boolean>(false);
    const [contactFormModel, setContactFormModel] = useState<ContactFormData>(defaultFormData);

    return (
        <>
            <HeaderSection onCtaClick={() => setShowContactForm(true)} />
            <AppResourcesSection />
            <WaitlistSection onCtaClick={(email: string) => {
                setShowContactForm(true);
                setContactFormModel({ ...contactFormModel, email: email, lgpd: true })
            }} />
            <Footer />

            <ContactForm
                show={showContactForm}
                sending={[sendingContactForm, setSendingContactForm]}
                formData={[contactFormModel, setContactFormModel]}
                onClose={() => {
                    setShowContactForm(false);
                    setSendingContactForm(false);
                    setContactFormModel(defaultFormData);
                }} />
        </>
    );
};