import { useState } from "react";

export const useToolbar = () => {
    const [toogled, setToogled] = useState<boolean>(false);

    const handleToogleButton = () => setToogled(!toogled);

    return {
        handleToogleButton, toogled
    };
};