import React from 'react';
import { Navigate, Route, Routes } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import { HomePage } from "./HomePage";
import { NotFoundPage } from "./NotFound";

export const App = () => {
  return (
    <>
      <GlobalStyle />

      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="*" element={<Navigate to="/404" />} />
        <Route path="/404" element={<NotFoundPage />} />
      </Routes>
    </>
  );
};

const GlobalStyle = createGlobalStyle`
    /* primary: #38B6FF */
    /* secondary: #FFDE59 */
    /* accent: ##FFFFFF */
    /* secondary-accent: #545454 */

    html {
        font-family: 'Open Sans', 'Inter', 'Helvetica Neue', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background-color: rgb(56, 189, 248);
    }

    .container {
        padding: 2rem;
    }

    .bg-primary { background-color: #38B6FF; }
    .bg-secondary { background-color: #FFDE59; }
    .bg-accent { background-color: #FFFFFF; }
    .text-primary { color: #38B6FF; }
    .text-secondary { color: #FFDE59; }
    .text-accent { color: #FFFFFF; }

`;
