import React, { FC } from "react";

export type ImageCardProps = {
    className?: string;
    icon?: string;
    title?: string;
    content?: string;
}

export const ImageCard: FC<ImageCardProps> = (props) => {
    return (
        <div className={`${props.className} rounded-3xl bg-sky-200 px-10 py-8 flex flex-col flex-wrap items-center justify-center text-center`}>
            <div className="rounded-full w-32 h-32 bg-white/80 p-3 flex flex-col items-center justify-center">
                <img src={props.icon} alt="icon imagecard" />
            </div>

            <h3 className="text-lg font-bold pt-7 pb-4">{props.title}</h3>
            <span className="text-sm">{props.content}</span>
        </div>
    );
};