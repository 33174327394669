import { Dispatch, SetStateAction } from "react";

export interface ContactFormData {
    nome?: string;
    email?: string;
    telefone?: string;
    empresa?: string;
    segmento?: string;
    faturamentoAnual?: string;
    mensagem?: string;
    setor?: string;
    cargo?: string;
    lgpd?: boolean;
    origem?: string;
}

export const defaultFormData: ContactFormData = {
    cargo: "",
    email: "",
    empresa: "",
    lgpd: true,
    faturamentoAnual: "",
    mensagem: "",
    nome: "",
    segmento: "",
    origem: 'pet_savior_landing_page',
    setor: "",
    telefone: "",
};

export type ContactFormProps = {
    show: boolean;
    formData: [ContactFormData, Dispatch<SetStateAction<ContactFormData>>];
    sending: [boolean, Dispatch<SetStateAction<boolean>>];
    onClose?: () => void;
}